import { useEffect, useState } from "react";
import { FormContainer, FormItem, StyledInput, PButton, StyledTextArea,  EEAT,
  EEATWRAP,
  EEATText, } from "./styled";
import { Row, Col } from "antd";
import Image from 'next/image';
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
console.log(FormContainer);
console.log(FormItem);
console.log(StyledInput);
console.log(PButton);
console.log(StyledTextArea);
console.log(EEAT);
console.log(EEATWRAP);
console.log(EEATText);
console.log(Loading);
console.log(ModalContactSuccess);
export default function FormConsultationLarge({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal,
  submitButton,
  dispatchDestination,
  formId,
}) {
  const [urlParams, setUrlParams] = useState(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, []);

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer name={formId} size="small" onFinish={(values) => handleSubmit({ ...values, ...urlParams })} form={form}>
        <Row className="background-red" gutter={16}>
          <Col md={12} xs={24}>
            <FormItem
              validateTrigger={"onSubmit"}
              name={"first_name"}
              rules={[
                {
                  required: true,
                  message: "First name required",
                },
              ]}
            >
              <StyledInput placeholder={"First Name"} />
            </FormItem>
            <FormItem
              validateTrigger={"onSubmit"}
              name={"last_name"}
              rules={[
                {
                  required: true,
                  message: "Last name required",
                },
              ]}
            >
              <StyledInput placeholder={"Last Name"} />
            </FormItem>
            <FormItem
              validateTrigger={"onBlur"}
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <StyledInput placeholder={"Email address"} />
            </FormItem>
            <FormItem
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: "Phone required",
                },
              ]}
              normalize={(value) => {
                const onlyNums = value.replace(/[^\d]/g, "");
                if (onlyNums.length >= 4 && onlyNums.length <= 6) {
                  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
                }
                if (onlyNums.length > 6 && onlyNums.length <= 10) {
                  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
                }
                if (onlyNums.length > 10) {
                  return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
                }
                return onlyNums;
              }
              }
            >
              <StyledInput placeholder={"Phone Number"} />
            </FormItem>
          </Col>
          <Col md={12} xs={24}>
            <Row gutter={4}>
              <Col span={14}>
                <FormItem
                  validateTrigger={"onSubmit"}
                  name={"city"}
                  rules={[
                    {
                      required: false,
                      message: "City required",
                    },
                  ]}
                >
                  <StyledInput placeholder={"City"} />
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem
                  name={"state"}
                  rules={[
                    {
                      required: false,
                      message: "State required",
                    },
                  ]}
                >
                  <StyledInput placeholder={"State"} />
                </FormItem>
              </Col>
            </Row>
            {dispatchDestination !== "franchise" ? <FormItem initialValue={referralCode} name={"referral_code"}>
              <StyledInput
                placeholder={"Promotional Code"}
                $primary={true}
              />
            </FormItem> : null}
            <FormItem
              name={"message"}
              rules={[
                {
                  required: false,
                  message: "Comments",
                },
              ]}
            >
              <StyledTextArea
                placeholder={"Comments"}
                rows={3}
              />
            </FormItem>
          </Col>
        </Row>
        <FormItem>
          <PButton
            data-large-cy="submit"
            ptype="primary"
            pname={submitButton || "GET STARTED"}
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            onSurface={true}
          />
        </FormItem>
        <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="trust icon"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>   
            <Image
              src="/images/eeat/alt/EEAT-02.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="trust icon"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="trust icon"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/alt/EEAT-04.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="trust icon"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </FormContainer>
      <Loading loading={loading} />
    </div>
  );
}
